import { Link, useStaticQuery, graphql, withPrefix } from "gatsby";
import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  createRef,
} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import Button from "react-bootstrap/Button";
import { StaticImage, getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import LocalBusinessSchema from "../components/schema/localBusinessSchema";
import { Helmet } from "react-helmet";
import gsap from "gsap";
import { motion } from "framer-motion";
import lottie from "lottie-web";

import animation from "../animations/testing.json";

import { MdEmail, MdOutlineDesktopMac, MdPhone } from "react-icons/md";
import { FaBuilding } from "react-icons/fa";
import { BsClockFill } from "react-icons/bs";
import ContactCard from "../components/contact-card";
import ContactForm from "../components/contactForm";
import Hero from "../components/hero";
import { StaticHero } from "../components/static-hero";

const ThankYouPage = () => {
  const tl = useRef();
  const data = useStaticQuery(graphql`
    query {
      # heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
      # 	altText
      # 	localFile {
      # 		publicURL
      # 		childImageSharp {
      # 			original {
      # 				height
      # 				width
      # 			}
      # 			gatsbyImageData(
      # 				formats: [AUTO, WEBP]
      # 				quality: 100
      # 				transformOptions: { cropFocus: CENTER, fit: CONTAIN }
      # 				layout: CONSTRAINED
      # 				placeholder: BLURRED
      # 			)
      # 		}
      # 	}
      # }

      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);
  // let animDiv = createRef();
  // useEffect(() => {
  // 	lottie.loadAnimation({
  // 		container: animDiv.current, // the dom element that will contain the animation: ;
  // 		renderer: "svg",
  // 		loop: true,
  // 		autoplay: true,
  // 		animationData: animation,
  // 	});
  // }, []);

  const siteUrl = data.site.siteMetadata.siteUrl;

  const propertyManagers = [
    {
      fullName: "Darren Faulkner",
      title: "Associate Director / Property Manager",
      email: "darrenf@priorspm.com",
      phone: "01273 980 234",
    },
    {
      fullName: "Paul Gibbs",
      title: "Property Manager",
      email: "paulg@priorspm.com",
      phone: "01273 980 233",
    },
    {
      fullName: "Isabel Kemp",
      title: "Assistant Property Manager",
      email: "Isabelk@priorspm.com",
      phone: "01273 980 236",
    },
    {
      fullName: "Adam McChesney",
      title: "Property Manager",
      email: "adammc@priprspm.com",
      phone: "01273 980 239",
    },
    {
      fullName: "Richard Redfern",
      title: "Property Manager",
      email: "richardr@priorspm.com",
      phone: "01273 980 235",
    },
  ];

  const otherContactsRow1 = [
    {
      fullName: "John Pakapoukas",
      title: "Accounts Manager",
      email: "richardr@priorspm.com",
      phone: "01273 980 235",
    },
    {
      fullName: "Rachel Phillips",
      title: "Accounts",
      email: "rachelp@priorspm.com",
      phone: "01273 737 586",
    },
  ];
  const otherContactsRow2 = [
    {
      fullName: "Paul Phillips",
      title: "Director / Chartered Building Surveyor",
      title2: "(Leasehold Valuation)",
      email: "paulp@priorspm.com",
      phone: "01273 980 242",
    },
    {
      fullName: "Jon Mahoney",
      title: "Building Surveyor",
      email: "jonm@priorspm.com",
      phone: "07555 743 730",
    },
    {
      fullName: "Mark Carter",
      title: "Chartered Surveyor",
      title2: "(Leasehold Valuation)",
      email: "markc@priorspm.com",
      phone: "01273 980 237",
    },
  ];

  const outOfHoursContacts = [
    {
      fullName: "M8FM Jason Pumfrey",
      email: "jason.pumpfrey@m8fm.uk",
      phone: "07724 043 338",
    },
    {
      fullName: "Excelsior Colin Peters",
      title: "(Worthing and Lancing Properties)",
      email: "colinpeters88@hotmail.co.uk",
      phone: "07852 874 911",
    },
  ];
  // const pluginImage = getImage(data.heroImg.childImageSharp.gatsbyImageData);

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <LocalBusinessSchema />
      <GatsbySeo
        title="Priors PM | Property Management Hove"
        description="Established residential and commercial block management company based in Hove"
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}`,
          title: "Priors PM | Property Management Hove",
          description:
            "Established residential and commercial block management company based in Hove",
          // images: [
          // 	{
          // 		url: `${data.heroImg?.localFile.publicURL}`,
          // 		width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
          // 		height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
          // 		alt: `${data.heroImg?.altText}`,
          // 	},
          // ],
        }}
      />

      <div>
        <Layout>
          {/* <Hero title={} description={} type={} backgroundImage={}/> */}
          {/* <StaticHero
            backgroundImage="../images/Adelaide-Crescent-Hove-3.jpg"
            text="We're rebuilding!"
          /> */}
          <Container className="text-white pt-10">
            <section className="mb-5 text-center">
              {/* <div style={{ height: "100vh" }}> */}
              <h1 className="pb-3">Thank you for contacting us</h1>
              <p>We will get back to you within as soon as possible</p>
              <Link className="contact-link" to="/">
                &#8592; Back home
              </Link>
              {/* </div> */}
            </section>
          </Container>
          {/* <BgImage
						Tag="section"
						className="hero"
						id="hero-home"
						image={pluginImage}
					>
						<Container className="h-100">
							<Row className="align-items-center h-100">
								<Col xs={12}>
									<h1
										className="text-uppercase text-white"
										id="home-hero-headline"
									>
										Lorem Ipsum
									</h1>
									<p className="text-white" id="home-hero-subheading">
										Lorem ipsum dolor sit amet, consectetur adipiscing elit.
										Nunc mollis nulla justo, quis venenatis enim tempor vitae.
										In hac habitasse platea dictumst. Nam lectus risus,
										convallis vel dictum eu, interdum a mi.
									</p>
								</Col>
							</Row>
						</Container>
					</BgImage> */}
        </Layout>
      </div>
    </>
  );
};

export default ThankYouPage;
